<template>
    <div class="register padding-50" id="registracia">
        <div class="container">
            <div class="row">
                <div class="col-md-6">
                    <div class="text">
                        <h3>Pridajte sa do nášho doTERRA tímu</h3>
                        <h4>
                            Staňte sa aj vy členmi
                            <span>komunity doTERRA</span>
                        </h4>
                        <p>
                            Otvorením Vášho veľkoobchodného účtu v doTERRA získate najmä:
                        </p>
                        <ul>
                            <li>25% zľavu z maloobchodných cien na produkty doTERRA</li>
                            <li>Kreditné body na nákup produktov s Programom vernostných odmien - LRP</li>
                            <li>Možnosť objednávania produktov cez Vašu virtuálnu kanceláriu z pohodlia Vašej obývačky</li>
                            <li>Možnosť získať produkt mesiaca zdarma a ďalšie zvýhodnené promočné produkty</li>
                            <li>Školenia, semináre a marketingovú podporu</li>
                            <li>Možnosť rozbehnúť Vaše podnikanie s doTERRA</li>
                        </ul>
                    </div>
                </div>
                <div class="col-md-6">
                    <div class="form">
                        <div class="row text-center" v-if="message && !loading">
                            <div class="col">
								<i v-if="success" class="fa fa-check reg-icon"></i>
								<i v-else class="fa fa-exclamation-triangle reg-icon"></i>
								<br>
                                <p class="success_error_message">{{ message }}</p>
                            </div>
                        </div>
						<div class="row text-center" v-if="loading">
                            <div class="col">
                                <p class="success_error_message">Načítavam...</p>
                            </div>
                        </div>
                        <form @submit.prevent="register()" v-if="!success">
                            <div class="row" v-if="step == 1 || step == 3">
                                <div class="input-field col-md-12">
                                    <label for>Meno a priezvisko</label>
                                    <input type="text" v-model="name">
                                </div>
                                <div class="input-field col-md-6">
                                    <label for>Email</label>
                                    <input type="email" v-model="email">
                                </div>
                                <div class="input-field col-md-6">
                                    <label for>Telefón</label>
                                    <input type="tel" v-model="phone">
                                </div>
                                <div class="col-md-12 text-right" v-if="step != 3">
                                    <button
                                        type="button"
                                        @click="changeStep(2)"
                                        class="btn-theme-dark button"
                                    >Pokračovať</button>
                                </div>
                            </div>
                            <div class="row" v-if="step == 2 || step == 3">
                                <div class="input-field col-md-12">
                                    <label>Odkiaľ ste sa dozvedeli o produktoch doTERRA?</label>
                                    <input type="text" v-model="text_products">
                                </div>
                                <div class="input-field col-md-12">
                                    <label>Bola Vám už ponúknutá možnosť od doTERRA konzultanta si otvoriť Váš vlastný veľkoobchodný účet?</label>
                                    <label>
                                        <input type="radio" v-model="text_account" value="Áno"> Áno
                                    </label>
                                    <label>
                                        <input type="radio" v-model="text_account" value="Nie"> Nie
                                    </label>
                                </div>
                                <div class="input-field col-md-12">
                                    <label>Vaša správa pre nás</label>
                                    <input type="text" v-model="text_message">
                                </div>
                                <div class="input-field col-md-12">
                                    <label for="gdpr_reg" class="gdpr_label">
                                        <input type="checkbox" id="gdpr_reg" v-model="gdpr"> Súhlasím so <a href="/gdpr" target="_blank">spracovaním osobných údajov</a> na účely registrácie
                                    </label>
                                </div>
                                <div class="col-md-12 text-right">
                                    <button
                                        type="button"
                                        @click="changeStep(1)"
                                        class="btn-theme-dark ghost button"
                                    >Späť</button>
                                    <button type="submit" class="btn-theme-dark button">Registrovať</button>
                                </div>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: function() {
        return {
            step: 1,
            email: null,
            name: null,
            phone: null,
            text_products: null,
            text_account: null,
            text_message: null,
            gdpr: false,
            success: false,
			message: "",
			loading: false
        };
    },
    methods: {
        changeStep(step) {
            this.message = "";
            this.step = step;
        },
        register() {
			this.loading = true;
			this.success = true;
            axios
                .post("/api/v1/register", {
                    email: this.email,
                    name: this.name,
                    phone: this.phone,
                    gdpr: this.gdpr,
                    text_products: this.text_products,
                    text_account: this.text_account,
                    text_message: this.text_message
                })
                .then(response => {
					this.loading = false;
                    this.success = true;
                    this.message =
						"Registrácia prebehla úspešne. Čoskoro Vás budeme kontaktovať.";
                })
                .catch(error => {
					this.success = false;
					this.loading = false;
                    this.step = 3;
                    this.message =
                        "Nastala chyba. Skontrolujte všetky vyplnené polia a zopakujte akciu.";
                });
        }
    }
};
</script>

