import { render, staticRenderFns } from "./VideoPopup.vue?vue&type=template&id=0b033954&scoped=true&"
import script from "./VideoPopup.vue?vue&type=script&lang=js&"
export * from "./VideoPopup.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0b033954",
  null
  
)

export default component.exports