<template>
	<div class="calendar">
		<div class="calendar__item" v-for="(item, index) in items" v-if="index < showingItems">
			<div class="row">
				<div class="col-lg-4 col-md-6">
					<div class="calendar__item-date">
						{{ item.date.split('-')[2] }}.{{ item.date.split('-')[1] }}.
						<div class="calendar__item-date--day">
							{{ item.day }}
						</div>
						<div class="calendar__item-date--time">
							o {{ item.time }}
						</div>
					</div>
				</div>
				<div class="col-lg-8 col-md-6">
					<h4>{{ item.name }}</h4>
					<p>{{ item.message }}</p>
					<a v-if="item.link" :href="item.link" target="_blank" class="button btn-theme-light">Viac o udalosti</a>
					<a v-else :href="'https://www.google.com/maps/@' + item.lat + ',' + item.long + '15z'" target="_blank" class="button btn-theme-light">Zobraziť miesto</a>
				</div>
			</div>
		</div>
		<div class="text-center" v-if="showingItems <= items.length">
			<a href="javascript:;" class="more-actions-cta" @click="showMoreItems()">Ďalšie akcie &raquo;</a>
		</div>
	</div>
</template>

<script>
export default {
	props: {
		items: Array
	},
	data: function() {
		return {
			showingItems: 3
		}
	},
	methods: {
		showMoreItems() {
			this.showingItems += 3;
		}
	}
}
</script>


