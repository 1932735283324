<template>
    <div>
        <div class="top-banner" @click="toggle()">
            <div class="bg">
                <div class="text">
                    <h3>
                        Prechádzka<br>svetom <span>aromaterapie</span>
                    </h3>
                    <div class="link-top-margin">
                        <span>Prehrať video</span>
                    </div>
                </div>
            </div>
        </div>
        <div id="video-popup" v-if="popup" @click="toggle()">
            <i class="material-icons">
                clear
            </i>
            <iframe width="1120" height="630" src="https://www.youtube.com/embed/OqKDkHfPJO8?controls=0" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
        </div>
    </div>
</template>

<script>
    export default {
        data: function () {
            return {
                popup: false
            }
        },
        created() {
            window.addEventListener('keydown', (e) => {
                if (e.key == 'Escape') {
                    this.popup = false;
                }
            });
        },
        methods: {
            toggle: function () {
                this.popup = !this.popup;
            }
        }
    }
</script>

<style scoped>
</style>