<template>
    <div class="newsletter padding-20">
        <div class="container">
            <div class="row">
                <div class="col-md-6 col-12">
                    <img src="/images/icons/newsletter.png" alt>
                    <h4>Novinky z blogu</h4>
                    <h3>Prihlásiť sa na odber</h3>
                </div>
                <div class="col-md-6 col-12">
					<p v-if="error"><b>{{ error }}</b></p>
					<p v-if="success">Ďakujeme za záujem. Pre prihlásenie potvrďte e-mail, ktorý Vám dorazí.</p>
                    <form @submit.prevent="subscribe()" v-else>
                        <label for="email">Email</label>
                        <input type="email" id="email" v-model="email">
                        <label for="gdpr" class="gdpr_label">
                            <input type="checkbox" id="gdpr" v-model="gdpr"> &nbsp; Súhlasím so <a href="/gdpr" target="_blank">spracovaním osobných údajov</a> na marketingové účely
                        </label>
                        <button class="button btn-theme-dark">Prihlásiť sa</button>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    data: function() {
        return {
            email: "@",
            gdpr: false,
            error: "",
            success: false
        };
    },
    methods: {
        subscribe() {
            axios
                .post("/api/v1/subscribe", {
                    email: this.email,
                    gdpr: this.gdpr
                })
                .then(response => {
					if(response.data) {
						this.success = true;
						this.error = '';
					} else {
						this.success = false;
						this.error = 'Skontrolujte všetky vpylnené polia a zopakujte akciu.';
					}
				})
                .catch(error => {
					// console.log(error);
				});
        }
    }
};
</script>
