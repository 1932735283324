<template>
    <div class="faq padding-50 grey-bg" id="najcastejsie-otazky">
        <div class="container">
            <div class="row">
                <div class="col-md-12">
                    <div class="section-title">
                        <h2>Najčastejšie otázky</h2>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div class="question">
                        <ul>
                            <li v-for="item in items" :class="{'active': item.id == activeID}" :key="item.id" @click="showQ(item.id)">
                                <h4>{{ item.question }}</h4>
                                <div class="answer mobile-show" v-html="item.answer">
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="col-lg-6">
                    <div v-for="item in items" :key="item.id" class="answer" v-if="item.id == activeID">
                        <div v-html="item.answer"></div>
                    </div>
                </div>
                <div class="col-md-12">
                    <div class="tel-contact">
                        <h3>Nedostali ste odpoveď na otázku?</h3>
                        <h3>
                            <a :href="'mailto:' + email">Napíšte nám na {{ email }}</a>
                        </h3>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        items: Array
    },
    data: function() {
        return {
			activeID: this.items[0].id,
			email: process.env.MIX_CONTACT_EMAIL
		};
	},
	methods: {
		showQ(id) {
			this.activeID = id;
		}
	},
	mounted() {
	}
};
</script>

